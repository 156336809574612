<template>
  <form>
    <p>{{ $t('user.sign_up_text') }}</p>
    <div class="d-flex justify--center mt-3">
      <va-button color="secondary" :href="'mailto:' + mail" class="my-0">{{ $t('user.send_email') }}</va-button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'signup',
  setup: () => {
    return {
      mail: process.env.VUE_APP_CONTACT_WEBSITE
    };
  }
};
</script>

<style lang="scss"></style>
